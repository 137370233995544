import { Grid } from '@material-ui/core';
import { LoginModal } from 'modules/User';

const Login = () => {
  return (
    <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
      <LoginModal />
    </Grid>
  );
};

export default Login;
