import { Grid, Typography } from '@material-ui/core';

type FormContainerProps = {
  label?: string;
};

const FormContainer: React.FC<FormContainerProps> = ({ label, children }) => {
  return (
    <Grid container direction="column" spacing={4}>
      {!!label && (
        <Grid item>
          <Typography variant="h3">{label}</Typography>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

export default FormContainer;
