import { Divider, Grid, Typography } from '@material-ui/core';
import { StatusColorBadge } from 'components/Common';

export type InOutFlowProps = {
  input: number;
  output: number;
};

const InOutFlow: React.FC<InOutFlowProps> = ({ input = 0, output = 0 }) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item>
      <Grid container alignItems="center">
        <StatusColorBadge status={input > output ? 'warning' : 'success'} />
      </Grid>
    </Grid>
    <Grid item>
      <Grid container item spacing={1}>
        <Grid item>
          <Typography>{input + ' '}</Typography>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Typography>{output + ' '}</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default InOutFlow;
