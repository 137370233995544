import { makeStyles } from '@material-ui/core';
import MuiLinearProgress from '@material-ui/core/LinearProgress';

type Palette = 'success' | 'warning' | 'error';

export type LinearProgressProps = {
  value: number;
  color: Palette;
};

const LinearProgress: React.FC<LinearProgressProps> = ({ color, value = 0 }) => {
  const styles = useStyles({ color });
  return (
    <MuiLinearProgress
      value={value}
      variant="determinate"
      classes={{
        bar: styles.progress,
      }}
    />
  );
};

const useStyles = makeStyles(({ palette }) => ({
  progress: {
    backgroundColor: ({ color }: { color: Palette }) => palette[color].main,
  },
}));

export default LinearProgress;
