import { TextField } from '@material-ui/core';
import { useInputStyles } from '../styles';

export type InputTimeProps = Pick<React.ComponentProps<typeof TextField>, 'disabled' | 'name' | 'onChange' | 'value'>;

const InputTime: React.FC<InputTimeProps> = (props) => {
  const styles = useInputStyles();

  return (
    <TextField
      InputProps={{ inputProps: { className: styles.input }, className: styles.container }}
      type="time"
      variant="outlined"
      margin="dense"
      fullWidth
      {...props}
    />
  );
};

export default InputTime;
