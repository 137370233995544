import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ArrowRight: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M15.9911 7.68003C15.99 7.23925 15.7925 6.82 15.4485 6.52803L7.99581 0.231072C7.59567 -0.0833882 7.00797 -0.0370711 6.6666 0.335828C6.32524 0.708727 6.35289 1.27419 6.72915 1.61475L12.4871 6.47875C12.5388 6.52279 12.5569 6.59288 12.5325 6.65495C12.508 6.71702 12.4463 6.75819 12.3771 6.75843H0.967813C0.437619 6.75843 0.0078125 7.17105 0.0078125 7.68003C0.0078125 8.18902 0.437619 8.60163 0.967813 8.60163H12.3751C12.4442 8.60227 12.5058 8.64358 12.5301 8.70563C12.5545 8.76769 12.5366 8.83772 12.4851 8.88195L6.72715 13.746C6.45289 13.9586 6.32211 14.2985 6.38651 14.6312C6.4509 14.964 6.7001 15.2361 7.03563 15.34C7.37115 15.4439 7.739 15.3629 7.99381 15.129L15.4478 8.83203C15.7922 8.54028 15.99 8.12095 15.9911 7.68003Z" />
    </SvgIcon>
  );
};

export default ArrowRight;
