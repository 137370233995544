import { Avatar, Box, Divider, Grid, Typography } from '@material-ui/core';
import backgroundOrnement from 'assets/background-ornement.svg';
import { Button } from 'components/Common';
import { LogoutIcon } from 'components/Common/Icons';
import styled from 'styled-components';

const ShiftedAvatar = styled(Avatar)`
  background-color: #f56f24;
  transform: translate3d(0, -50%, 0);
  margin-bottom: -50%;
` as typeof Avatar;

type SidebarActionsProps = {
  onLogout: () => void;
};

const SidebarActionsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  background-image: url(${backgroundOrnement});
  background-repeat: no-repeat;
  background-position: bottom right;
  z-index: 1;
`;

const SidebarActions: React.FC<SidebarActionsProps> = ({ onLogout }) => {
  return (
    <SidebarActionsContainer>
      <Divider />
      <Box marginX={6} marginBottom={6}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <ShiftedAvatar>JD</ShiftedAvatar>
          </Grid>
          <Grid item>
            <Typography variant="h4">Centre hospitalier de Périgueux</Typography>
          </Grid>
          <Grid item>
            <Typography>john.doe@ambuliz.com</Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Button variant="text" size="small" color="default" startIcon={<LogoutIcon />} onClick={onLogout}>
              Se déconnecter
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SidebarActionsContainer>
  );
};

export default SidebarActions;
