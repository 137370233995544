import { Box, CircularProgress, makeStyles, SvgIconProps } from '@material-ui/core';
import { useEffect, useState } from 'react';

type PaletteColor = 'primary' | 'secondary' | 'warning' | 'error' | 'success';
const useStyles = makeStyles(({ palette }) => ({
  icon: {
    position: 'absolute',
    backgroundColor: ({ color }: { color?: PaletteColor }) => (color ? palette[color].light : 'inherit'),
    color: ({ color }: { color?: PaletteColor }) => (color ? palette[color].main : 'inherit'),
    borderRadius: '50%',
    padding: 3,
    border: '5px white solid',
  },
  circularProgress: {
    color: ({ color }: { color?: PaletteColor }) => (color ? palette[color].main : 'inherit'),
  },
}));

export type CircularProgressIconProps = {
  Icon: React.FC<SvgIconProps>;
  color: PaletteColor;
  value: number;
};
const CircularProgressIcon: React.FC<CircularProgressIconProps> = ({ color, value = 0, Icon }) => {
  const styles = useStyles({ color });
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        return Math.min(oldProgress + 10, value);
      });
    }, 60);

    return () => {
      clearInterval(timer);
    };
  }, [value]);

  return (
    <Box position="relative">
      <Icon className={styles.icon} fontSize="large" color="inherit" />
      <CircularProgress variant="determinate" value={progress} color="inherit" className={styles.circularProgress} />
    </Box>
  );
};

export default CircularProgressIcon;
