import { Box, Divider, Grid } from '@material-ui/core';
import logo from './logo.svg';

const SidebarHeader: React.FC = () => {
  return (
    <Box paddingX={2}>
      <Grid container justify="center" alignItems="center" style={{ minHeight: '96px' }}>
        <img src={logo} width="90px" alt="ambuliz-logo" />
      </Grid>
      <Divider />
    </Box>
  );
};

export default SidebarHeader;
