import { normalize } from 'normalizr';
import NormalizedUser from './NormalizedUser';
import UserSchema from './UserSchema';

const normalizeUser = (user?: Parse.User<Parse.Attributes>): NormalizedUser | undefined => {
  if (user) {
    const normalizedUsers = normalize(user.toJSON(), UserSchema);
    return normalizedUsers.entities.User ? normalizedUsers.entities.User[user.id] : undefined;
  }
  return undefined;
};

export type { NormalizedUser };
export { normalizeUser };
