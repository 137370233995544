import { Shadows } from '@material-ui/core/styles/shadows';

const shadows: Shadows = [
  'none',
  '0px 1px 2px rgba(17, 24, 39, 0.05)',
  '0px 1px 3px rgba(17, 24, 39, 0.1), 0px 1px 2px rgba(17, 24, 39, 0.06)',
  '0px 4px 6px -1px rgba(17, 24, 39, 0.1), 0px 2px 4px -1px rgba(17, 24, 39, 0.06)',
  '0px 1px 8px rgba(17, 24, 39, 0.15)',
  '0px 20px 25px -5px rgba(17, 24, 39, 0.1), 0px 10px 10px -5px rgba(17, 24, 39, 0.04)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
];

export default shadows;
