import { SvgIconProps, Theme, withTheme } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import styled from 'styled-components';

export type StatusColorBadgeProps = SvgIconProps & {
  status?: 'warning' | 'success' | 'error';
};

type StatusColorBadgeIconProps = StatusColorBadgeProps & { theme: Theme };

const StatusColorBadge: React.FC<StatusColorBadgeProps> = withTheme(
  styled(FiberManualRecord)`
    font-size: 0.9rem;
    color: ${(props: StatusColorBadgeIconProps) => {
      return props.status && props.theme.palette[props.status].main;
    }};
  `
) as typeof FiberManualRecord;

export default StatusColorBadge;
