import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Button } from 'components/Common';
import { UnitOptionModal } from 'components/Dashboard/Unit/UnitOption';
import { format } from 'date-fns';
import { i18n } from 'locale';
import React, { useState } from 'react';

export type StatisticsCardProps = {
  action?: boolean;
  title: string;
  loading: boolean;
};

const StatisticsCard: React.FC<StatisticsCardProps> = ({ children, title, loading, action }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Card>
          <CardHeader
            title={loading ? <Skeleton width={180} style={{ borderRadius: 4 }} animation="wave" /> : title}
            action={
              !loading &&
              action && (
                <Button variant="text" color="default" size="small" onClick={() => setEditModalOpen(true)}>
                  {i18n.edit}
                </Button>
              )
            }
          />
          <CardContent>
            <Grid container spacing={10}>
              {children}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          {loading ? (
            <Skeleton width={180} style={{ borderRadius: 4 }} animation="wave" />
          ) : (
            <>
              {`${i18n.updatedAt}: `}
              <Typography component="span" variant="subtitle2" color="textPrimary" style={{ fontWeight: 600 }}>
                {format(new Date(), 'dd/MM/yyyy à HH:mm')}
              </Typography>
            </>
          )}
        </Typography>
      </Grid>
      <UnitOptionModal open={editModalOpen} onClose={() => setEditModalOpen(false)} />
    </Grid>
  );
};

export default StatisticsCard;
