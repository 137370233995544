import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PinMapIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 14 16">
      <path d="M11.4665 2.66669C11.4164 2.66668 11.369 2.68916 11.3374 2.72793C11.3057 2.7667 11.2932 2.81764 11.3032 2.86669C11.3615 3.1492 11.3912 3.43688 11.3918 3.72535C11.3918 5.85402 8.70317 8.37602 7.8785 9.09802C7.37559 9.53866 6.62408 9.53866 6.12117 9.09802C5.2965 8.37602 2.60784 5.85402 2.60784 3.72535C2.60847 3.43688 2.63817 3.1492 2.6965 2.86669C2.70651 2.81764 2.69395 2.7667 2.66229 2.72793C2.63063 2.68916 2.58322 2.66668 2.53317 2.66669H1.6665C1.11422 2.66669 0.666504 3.1144 0.666504 3.66669V14.6667C0.666504 15.219 1.11422 15.6667 1.6665 15.6667H12.3332C12.8855 15.6667 13.3332 15.219 13.3332 14.6667V3.66669C13.3332 3.1144 12.8855 2.66669 12.3332 2.66669H11.4665Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60791 3.72534C3.61011 1.85291 5.12747 0.335548 6.99991 0.333344C8.87235 0.335548 10.3897 1.85291 10.3919 3.72534C10.3919 5.51401 7.54391 8.06134 7.21991 8.34601C7.09407 8.45656 6.90575 8.45656 6.77991 8.34601C6.45591 8.05868 3.60791 5.51401 3.60791 3.72534ZM7.83324 3.50001C7.83324 3.03977 7.46014 2.66668 6.99991 2.66668C6.53967 2.66668 6.16657 3.03977 6.16657 3.50001C6.16657 3.96025 6.53967 4.33334 6.99991 4.33334C7.46014 4.33334 7.83324 3.96025 7.83324 3.50001Z"
      />
    </SvgIcon>
  );
};

export default PinMapIcon;
