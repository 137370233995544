import UserContext from 'context/UserContext';
import { HospitalGroupReporting, Login, MyHealthCenter, MyUnit, TerritorialReporting } from 'pages';
import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { UnitsContextProvider } from './context/UnitsContext';

const App = () => {
  const { isLoggedIn } = useContext(UserContext);
  return isLoggedIn ? (
    <Switch>
      <Route path="/reporting/territorial">
        <TerritorialReporting />
      </Route>
      <Route path="/reporting/hospital-group">
        <HospitalGroupReporting />
      </Route>
      <Route path="/management/my-health-center">
        <UnitsContextProvider>
          <MyHealthCenter />
          {/* <Route path="/management/my-health-center/unit/:id">
              <UnitEditModal />
            </Route> */}
        </UnitsContextProvider>
      </Route>
      <Route path="/management/my-unit">
        <MyUnit />
      </Route>
      <Redirect to="/management/my-health-center" />
    </Switch>
  ) : (
    <Login />
  );
};

export default App;
