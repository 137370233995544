import { SvgIcon, SvgIconProps } from '@material-ui/core';

const HospitalShield: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6953 0.00404358C15.413 0.00476279 15.9956 0.584406 16 1.30204L15.992 6.13338C15.9984 10.8368 12.7561 14.9215 8.174 15.9827C8.08606 16.0011 7.99527 16.0011 7.90733 15.9827C3.31544 14.9631 0.0360181 10.9076 0 6.20404L0.008 1.30938C0.00871922 0.591358 0.58866 0.00843926 1.30667 0.00404358H14.6953ZM11.9 8.73538C12.1393 8.73538 12.3333 8.54137 12.3333 8.30204V5.70404C12.3333 5.46472 12.1393 5.27071 11.9 5.27071H10.0667C9.88257 5.27071 9.73333 5.12147 9.73333 4.93738V3.10404C9.73333 2.86472 9.53932 2.67071 9.3 2.67071H6.7C6.46068 2.67071 6.26667 2.86472 6.26667 3.10404V4.93538C6.26667 5.11947 6.11743 5.26871 5.93333 5.26871H4.1C3.86068 5.26871 3.66667 5.46272 3.66667 5.70204V8.30204C3.66667 8.41697 3.71232 8.52719 3.79359 8.60846C3.87485 8.68972 3.98507 8.73538 4.1 8.73538H5.93333C6.11743 8.73538 6.26667 8.88461 6.26667 9.06871V10.902C6.26667 11.1414 6.46068 11.3354 6.7 11.3354H9.3C9.53932 11.3354 9.73333 11.1414 9.73333 10.902V9.06871C9.73333 8.88461 9.88257 8.73538 10.0667 8.73538H11.9Z"
      />
    </SvgIcon>
  );
};

export default HospitalShield;
