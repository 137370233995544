type ColorName = 'blue' | 'grey' | 'orange' | 'green' | 'red';

type Shade = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type ColorShades = Record<Shade, string>;

const color: Record<ColorName, ColorShades> = {
  blue: {
    50: '#F5F9FF',
    100: '#C1DAFF',
    200: '#97C0FF',
    300: '#6CA6FF',
    400: '#428DFF',
    500: '#2E74DD',
    600: '#1E5CBB',
    700: '#114399',
    800: '#072D77',
    900: '#010D32',
  },
  grey: {
    50: '#F8FAFC',
    100: '#F1F5F9',
    200: '#E2E8F0',
    300: '#CBD5E1',
    400: '#94A3B8',
    500: '#64748B',
    600: '#475569',
    700: '#334155',
    800: '#1E293B',
    900: '#0F172A',
  },
  orange: {
    50: '#FFF4ED',
    100: '#FFDBC7',
    200: '#FFC2A0',
    300: '#FFA979',
    400: '#FB8E51',
    500: '#F56F24',
    600: '#D45814',
    700: '#B4480B',
    800: '#883404',
    900: '#512208',
  },
  green: {
    50: '#EDFFF9',
    100: '#C4FFEC',
    200: '#9BFFDF',
    300: '#72FDD0',
    400: '#44ECB6',
    500: '#2FCA98',
    600: '#1DA87B',
    700: '#108660',
    800: '#066446',
    900: '#00422D',
  },
  red: {
    50: '',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '#F95757',
    600: '',
    700: '',
    800: '',
    900: '',
  },
};

export default color;
