import { Grid } from '@material-ui/core';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import { Button } from 'components/Common';
import { DashboardHeaderTitle } from 'components/Dashboard';
import { i18n } from 'locale';
import Layout from 'modules/Layout';

const HospitalGroupReporting = () => {
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item container justify="space-between" alignItems="center" xs={12}>
          <Grid item>
            <DashboardHeaderTitle title="Reporting GHT de la Dordogne" subtitle="Mis à jour le 26/04 à 17:00" />
          </Grid>
          <Grid item>
            <Button endIcon={<OpenInNewRoundedIcon />}>{i18n.exportData}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default HospitalGroupReporting;
