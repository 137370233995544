import { SvgIcon, SvgIconProps } from '@material-ui/core';

const HospitalBuilding: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3C8 4.65685 6.65685 6 5 6C3.34391 5.99816 2.00184 4.65609 2 3C2 1.34315 3.34315 0 5 0C6.65685 0 8 1.34315 8 3ZM4.16667 2.5H3.66667C3.39052 2.5 3.16667 2.72386 3.16667 3C3.16667 3.27614 3.39052 3.5 3.66667 3.5H4.16667C4.35076 3.5 4.5 3.64924 4.5 3.83333V4.33333C4.5 4.60948 4.72386 4.83333 5 4.83333C5.27614 4.83333 5.5 4.60948 5.5 4.33333V3.83333C5.5 3.64924 5.64924 3.5 5.83333 3.5H6.33333C6.60948 3.5 6.83333 3.27614 6.83333 3C6.83333 2.72386 6.60948 2.5 6.33333 2.5H5.83333C5.64924 2.5 5.5 2.35076 5.5 2.16667V1.66667C5.5 1.39052 5.27614 1.16667 5 1.16667C4.72386 1.16667 4.5 1.39052 4.5 1.66667V2.16667C4.5 2.35076 4.35076 2.5 4.16667 2.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66673 8.66666H7.66673C6.56216 8.66666 5.66673 9.56209 5.66673 10.6667V15.3333C5.66669 15.426 5.67449 15.5186 5.69007 15.61C5.70678 15.7069 5.67985 15.8063 5.61648 15.8815C5.5531 15.9567 5.45975 16 5.3614 16H0.666732C0.298542 16 6.50088e-05 15.7015 6.50088e-05 15.3333V4.66666C-0.00494637 4.23007 0.280232 3.84312 0.698732 3.71866C0.786484 3.6916 0.88151 3.70194 0.961386 3.74724C1.04126 3.79254 1.09891 3.86879 1.12073 3.95799C1.55947 5.74402 3.16094 6.99969 5.00007 6.99969C6.83919 6.99969 8.44066 5.74402 8.8794 3.95799C8.90122 3.86879 8.95887 3.79254 9.03874 3.74724C9.11862 3.70194 9.21365 3.6916 9.3014 3.71866C9.7199 3.84312 10.0051 4.23007 10.0001 4.66666V8.33332C10.0001 8.51742 9.85083 8.66666 9.66673 8.66666ZM2.00006 13.8333H4.00006C4.2762 13.8333 4.50006 13.6095 4.50006 13.3333C4.50006 13.0572 4.2762 12.8333 4.00006 12.8333H2.00006C1.72391 12.8333 1.50006 13.0572 1.50006 13.3333C1.50006 13.6095 1.72391 13.8333 2.00006 13.8333ZM4.00006 11.5H2.00006C1.72391 11.5 1.50006 11.2761 1.50006 11C1.50006 10.7238 1.72391 10.5 2.00006 10.5H4.00006C4.2762 10.5 4.50006 10.7238 4.50006 11C4.50006 11.2761 4.2762 11.5 4.00006 11.5ZM2.00006 9.16665H4.33339C4.60953 9.16665 4.83339 8.9428 4.83339 8.66665C4.83339 8.39051 4.60953 8.16665 4.33339 8.16665H2.00006C1.72391 8.16665 1.50006 8.39051 1.50006 8.66665C1.50006 8.9428 1.72391 9.16665 2.00006 9.16665Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0001 9.66666H7.66675C7.11446 9.66666 6.66675 10.1144 6.66675 10.6667V15.3333C6.66675 15.7015 6.96522 16 7.33341 16H9.66675C9.85084 16 10.0001 15.8508 10.0001 15.6667V15C10.0001 14.2636 10.597 13.6667 11.3334 13.6667C12.0698 13.6667 12.6667 14.2636 12.6667 15V15.6667C12.6667 15.8508 12.816 16 13.0001 16H15.3334C15.7016 16 16.0001 15.7015 16.0001 15.3333V10.6667C16.0001 10.1144 15.5524 9.66666 15.0001 9.66666ZM10.3334 12.1667H8.66675C8.39061 12.1667 8.16675 11.9428 8.16675 11.6667C8.16675 11.3905 8.39061 11.1667 8.66675 11.1667H10.3334C10.6096 11.1667 10.8334 11.3905 10.8334 11.6667C10.8334 11.9428 10.6096 12.1667 10.3334 12.1667ZM12.3334 12.1667H14.0001C14.2762 12.1667 14.5001 11.9428 14.5001 11.6667C14.5001 11.3905 14.2762 11.1667 14.0001 11.1667H12.3334C12.0573 11.1667 11.8334 11.3905 11.8334 11.6667C11.8334 11.9428 12.0573 12.1667 12.3334 12.1667Z"
      />
    </SvgIcon>
  );
};

export default HospitalBuilding;
