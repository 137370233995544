import { Dialog, DialogContent, Grid, Slide, Typography } from '@material-ui/core';
import { Button } from 'components/Common';
import { ArrowLeft } from 'components/Common/Icons';
import { i18n } from 'locale';
import { useState } from 'react';
import { NormalizedUnit } from 'types';
import UnitEditModalForm from './UnitEditModalForm';
import UnitEditModalUnitCard from './UnitEditModalUnitCard';

export type UnitEditModalProps = {
  name: string;
  units: NormalizedUnit[];
  open: boolean;
  onClose: () => void;
  loading?: boolean;
};

const UnitEditModal: React.FC<UnitEditModalProps> = ({ name, open = false, units = [], onClose, loading = false }) => {
  const [selectedUnit, setSelectedUnit] = useState(units[0]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={loading ? () => {} : onClose}
      fullScreen
      TransitionComponent={Slide}
      //@ts-ignore
      TransitionProps={{ direction: 'left' }}
      style={{
        zIndex: 0,
        left: '240px',
      }}
      hideBackdrop
    >
      <DialogContent>
        <Grid container spacing={8}>
          <Grid item container>
            <Grid item xs={12}>
              <Typography variant="h1">
                {i18n.editDivision} - {name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button startIcon={<ArrowLeft />} variant="text" size="small" onClick={onClose}>
                {i18n.goBackToHealthCenter}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={4} direction="column">
              <Grid item>
                <Typography variant="h5">{i18n.unitToEdit}</Typography>
              </Grid>
              {units.map((unit) => (
                <Grid item key={unit.objectId}>
                  <UnitEditModalUnitCard
                    selected={unit.objectId === selectedUnit.objectId}
                    name={unit.name}
                    onClick={() => setSelectedUnit(unit)}
                    updatedAt={unit.lastUpdatedAt.toISOString()}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <UnitEditModalForm {...selectedUnit} onSubmit={() => console.log('OK')} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UnitEditModal;
