import { Box } from '@material-ui/core';
import Sidebar from 'components/Sidebar';
import UserContext from 'context/UserContext';
import { useContext } from 'react';

const Layout: React.FC = ({ children }) => {
  const { logout } = useContext(UserContext);

  return (
    <>
      <Sidebar onLogout={logout} />
      <Box marginLeft="240px" paddingX={10} paddingTop={6} overflow="hidden">
        {children}
      </Box>
    </>
  );
};

export default Layout;
