import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from 'components/Common';
import { InputText } from 'components/Common/Form';
import logo from 'components/Sidebar/logo.svg';
import { i18n } from 'locale';
import { ChangeEvent, FocusEvent, FormEvent, useState } from 'react';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';

export type LoginFormProps = {
  loading: boolean;
  onSubmit: (email: string, password: string) => void;
  error?: string;
};

type SchemaError<T> = Partial<{ [key in keyof T]: string }>;

function validate<T>(form: any, schema: SchemaOf<T>) {
  const errors: any = {};
  Object.keys(form).forEach((key) => {
    try {
      schema.validateSyncAt(key, form);
    } catch (error) {
      errors[key] = error.message;
    }
  });
  return Object.values(errors).length > 0 ? errors : null;
}

type FormData = {
  username: string;
  password: string;
};

const FormSchema: Yup.SchemaOf<FormData> = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, loading = false, error }) => {
  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState<SchemaError<FormData>>({});

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validate(state, FormSchema);
    if (errors) {
      setErrors(errors);
    } else {
      onSubmit(state.username, state.password);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: undefined });
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: undefined });
  };

  return (
    <Grid container item xs={8} md={4} lg={3} spacing={4} direction="column" component="form" onSubmit={handleSubmit}>
      <Grid container item justify="center" alignItems="center">
        <Grid item xs={5}>
          <img src={logo} alt="Ambuliz logo" width="100%" />
        </Grid>
      </Grid>
      <Grid item>
        <InputText
          disabled={loading}
          label={i18n.username}
          value={state.username}
          onChange={handleChange}
          onFocus={handleFocus}
          name="username"
          error={errors.username}
        />
      </Grid>
      <Grid item>
        <InputText
          disabled={loading}
          label={i18n.password}
          type="password"
          value={state.password}
          onChange={handleChange}
          onFocus={handleFocus}
          name="password"
          error={errors.password}
        />
      </Grid>
      <Grid item container justify="flex-end">
        <Button type="submit" loading={loading}>
          {i18n.login}
        </Button>
        <Grid item xs={12}>
          <Box marginTop={1}>
            <Typography align="right" color="error" variant="body2">
              {error}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
