import { Box, Divider, Grid, Typography } from '@material-ui/core';

export type FormSectionProps = {
  label?: string;
};

const FormSection: React.FC<FormSectionProps> = ({ label, children }) => {
  return (
    <Grid item>
      <Box marginBottom={4}>
        <Divider />
      </Box>
      {!!label && (
        <Box marginBottom={2}>
          <Typography variant="h4">{label}</Typography>
        </Box>
      )}
      {children}
    </Grid>
  );
};

export default FormSection;
