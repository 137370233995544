import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Edit: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M12.8 7.65979C12.4465 7.65979 12.16 7.95827 12.16 8.32646V13.9931C12.16 14.3613 11.8735 14.6598 11.52 14.6598H1.92C1.56654 14.6598 1.28 14.3613 1.28 13.9931V2.65979C1.28 2.2916 1.56654 1.99312 1.92 1.99312H8.32C8.67346 1.99312 8.96 1.69465 8.96 1.32646C8.96 0.958267 8.67346 0.65979 8.32 0.65979H1.92C0.859613 0.65979 0 1.55522 0 2.65979V13.9931C0 15.0977 0.859613 15.9931 1.92 15.9931H11.52C12.5804 15.9931 13.44 15.0977 13.44 13.9931V8.32646C13.44 7.95827 13.1535 7.65979 12.8 7.65979Z" />
      <path d="M14.986 0.385281C14.4795 -0.118255 13.6825 -0.118255 13.1761 0.385281L12.497 1.09261C12.3721 1.22278 12.3721 1.43378 12.497 1.56395L13.8545 2.97795C13.9794 3.10808 14.182 3.10808 14.3069 2.97795L14.986 2.27061C15.4857 1.74995 15.4857 0.905948 14.986 0.385281Z" />
      <path d="M7.26947 7.00796L8.62691 8.42196C8.75187 8.55209 8.95443 8.55209 9.07939 8.42196L13.1524 4.17929C13.2125 4.11676 13.2464 4.03184 13.2464 3.94329C13.2464 3.85474 13.2125 3.76983 13.1524 3.70729L11.7943 2.29263C11.6671 2.16808 11.4689 2.16808 11.3418 2.29263L7.26947 6.53663C7.14455 6.66679 7.14455 6.87779 7.26947 7.00796Z" />
      <path d="M6.62323 7.49039C6.51867 7.51297 6.43152 7.58779 6.39027 7.69039L5.61459 9.71372C5.56717 9.83752 5.59511 9.97885 5.68563 10.0731C5.77828 10.163 5.91137 10.1915 6.03059 10.1471L7.96979 9.33906C8.06973 9.29748 8.14291 9.20636 8.16456 9.09656C8.18621 8.98675 8.15343 8.87296 8.07731 8.79372L6.91187 7.58172C6.83633 7.50299 6.72805 7.46872 6.62323 7.49039Z" />
    </SvgIcon>
  );
};

export default Edit;
