import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ArrowLeft: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M15.032 7.04074H3.62462C3.55555 7.04007 3.49399 6.99704 3.46964 6.9324C3.44529 6.86776 3.46316 6.79481 3.51462 6.74874L9.27262 1.68207C9.64887 1.32732 9.67653 0.738292 9.33516 0.349856C8.9938 -0.0385802 8.4061 -0.0868271 8.00595 0.240736L0.551953 6.80007C0.207553 7.1038 0.0102539 7.54087 0.0102539 8.00007C0.0102539 8.45927 0.207553 8.89634 0.551953 9.20007L8.00462 15.7581C8.40494 16.0814 8.98901 16.0316 9.32879 15.6451C9.66858 15.2587 9.64321 14.673 9.27129 14.3174L3.51329 9.25074C3.46182 9.20467 3.44396 9.13171 3.46831 9.06707C3.49266 9.00243 3.55422 8.9594 3.62329 8.95874H15.032C15.5521 8.94503 15.9667 8.51941 15.9667 7.99907C15.9667 7.47873 15.5521 7.05311 15.032 7.0394V7.04074Z" />
    </SvgIcon>
  );
};

export default ArrowLeft;
