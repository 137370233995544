import { Checkbox, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { ChevronDown } from 'components/Common/Icons';
import { ChangeEvent } from 'react';

export type MultipleSelectProps = {
  label: string;
  value: string[];
  options: Record<string, string>;
  onChange: (value: string[]) => void;
};

const MultipleSelect: React.FC<MultipleSelectProps> = ({ label, value, onChange, options }) => {
  const handleChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onChange(event.target.value as string[]);
  };

  return (
    <Select
      value={value}
      fullWidth
      multiple
      IconComponent={ChevronDown}
      renderValue={(selected: unknown) => {
        const selectedOptions: string[] = selected as string[];
        return (
          <Typography className={selectedOptions.length > 0 ? 'active' : ''}>
            {label} ({selectedOptions.length})
          </Typography>
        );
      }}
      onChange={handleChange}
    >
      {Object.keys(options).map((key) => (
        <MenuItem value={key} key={key}>
          <Checkbox checked={value.includes(key)} style={{ color: 'inherit' }} />
          <ListItemText primary={options[key]} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultipleSelect;
