import { SvgIcon, SvgIconProps } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  to {
    transform: translateY(-4px);
  }
`;

const BouncingDotsContainer = styled(SvgIcon)`
  & > circle {
    animation: ${bounce} 600ms infinite alternate;
  }
  & > circle:nth-of-type(2) {
    animation-delay: 250ms;
  }
  & > circle:nth-of-type(3) {
    animation-delay: 350ms;
  }
`;

const BouncingDots: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <BouncingDotsContainer {...rest} viewBox="0 0 20 20">
      <circle cx="2" cy="12" r="2" />
      <circle cx="10" cy="12" r="2" />
      <circle cx="18" cy="12" r="2" />
    </BouncingDotsContainer>
  );
};

export default BouncingDots;
