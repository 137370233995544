import { SvgIcon, SvgIconProps } from '@material-ui/core';

const LogoutIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M9 11.6663C8.63181 11.6663 8.33333 11.9648 8.33333 12.333V13.9997C8.33333 14.1838 8.1841 14.333 8 14.333H1.66667C1.48257 14.333 1.33333 14.1838 1.33333 13.9997V1.99967C1.33333 1.81558 1.48257 1.66634 1.66667 1.66634H8C8.1841 1.66634 8.33333 1.81558 8.33333 1.99967V3.66634C8.33333 4.03453 8.63181 4.33301 9 4.33301C9.36819 4.33301 9.66667 4.03453 9.66667 3.66634V1.66634C9.66667 0.929961 9.06971 0.333008 8.33333 0.333008H1.33333C0.596954 0.333008 0 0.929961 0 1.66634V14.333C0 15.0694 0.596954 15.6663 1.33333 15.6663H8.33333C9.06971 15.6663 9.66667 15.0694 9.66667 14.333V12.333C9.66667 11.9648 9.36819 11.6663 9 11.6663Z" />
      <path d="M15.7032 7.77938L11.7032 5.11271C11.4987 4.97629 11.2357 4.96345 11.019 5.0793C10.8022 5.19516 10.6667 5.42091 10.6665 5.66671V7.33338H4.6665C4.30924 7.33338 3.97911 7.52398 3.80048 7.83338C3.62185 8.14278 3.62185 8.52398 3.80048 8.83338C3.97911 9.14278 4.30924 9.33338 4.6665 9.33338H10.6665V11C10.6715 11.3661 10.9671 11.6617 11.3332 11.6667C11.4649 11.6667 11.5936 11.6278 11.7032 11.5547L15.7032 8.88804C15.8886 8.7644 16 8.55626 16 8.33338C16 8.1105 15.8886 7.90236 15.7032 7.77871V7.77938Z" />
    </SvgIcon>
  );
};

export default LogoutIcon;
