import Api from '@ambuliz/sabri-core';
import React, { createContext, useState } from 'react';
import { NormalizedUser } from 'types';
import { normalizeUser } from 'types/User';

const useUserContextProvider = () => {
  const [currentUser, setCurrentUser] = useState<NormalizedUser | undefined>(normalizeUser(Api.User.current()));

  const login = async (username: string, password: string) => {
    setCurrentUser(normalizeUser(await Api.User.logIn(username, password)));
  };

  const logout = async () => {
    await Api.User.logOut();
    setCurrentUser(undefined);
  };

  return { currentUser, login, logout, isLoggedIn: !!currentUser };
};

type UserContextType = ReturnType<typeof useUserContextProvider>;
const UserContext = createContext({} as UserContextType);

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useUserContextProvider();
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
