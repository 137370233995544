import { Drawer, Grid } from '@material-ui/core';
import SidebarActions from './SidebarActions';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';

export type SidebarProps = {
  onLogout: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({ onLogout = () => {} }) => {
  return (
    <Drawer elevation={3} variant="permanent">
      <Grid container direction="column" justify="space-between" style={{ maxWidth: '240px', minHeight: '100%' }}>
        <Grid item>
          <SidebarHeader />
          <SidebarMenu />
        </Grid>
        <Grid item>
          <SidebarActions onLogout={onLogout} />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Sidebar;
