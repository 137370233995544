import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Group: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M4.28976 10.6622C4.28976 9.56109 4.77241 8.56562 5.55672 7.8718C5.27015 7.58523 4.92324 7.37406 4.516 7.23832C5.02882 6.98191 5.39081 6.43893 5.39081 5.82053C5.39081 4.94572 4.68191 4.23682 3.8071 4.23682C2.93229 4.23682 2.22339 4.94572 2.22339 5.82053C2.22339 6.43893 2.5703 6.98191 3.0982 7.23832C2.07256 7.55506 1.3335 8.49021 1.3335 9.60634C1.3335 10.8582 2.26864 10.9186 3.47528 10.9186C3.58086 10.9186 3.70152 10.9186 3.82218 10.9186C3.94285 10.9186 4.04843 10.9186 4.16909 10.9186C4.22943 10.9186 4.27467 10.9186 4.335 10.9186C4.28975 10.8431 4.28976 10.7526 4.28976 10.6622Z" />
      <path d="M12.8872 7.25334C13.4001 6.99693 13.762 6.45394 13.762 5.83554C13.762 4.96073 13.0531 4.25183 12.1783 4.25183C11.3035 4.25183 10.5946 4.96073 10.5946 5.83554C10.5946 6.45394 10.9415 6.99693 11.4694 7.25334C11.0773 7.37401 10.7153 7.60025 10.4287 7.88683C11.213 8.56556 11.6957 9.57612 11.6957 10.6772C11.6957 10.7677 11.6957 10.8582 11.6806 10.9487C11.7258 10.9487 11.7862 10.9487 11.8314 10.9487C11.9521 10.9487 12.0577 10.9487 12.1783 10.9487C12.299 10.9487 12.4197 10.9487 12.5252 10.9487C13.7319 10.9487 14.667 10.8883 14.667 9.63645C14.6519 8.50523 13.9129 7.57008 12.8872 7.25334Z" />
      <path d="M7.53243 12.3816C7.66817 12.3816 7.83409 12.3816 7.98492 12.3816C8.13575 12.3816 8.28658 12.3816 8.43741 12.3816C10.006 12.3816 11.2278 12.3062 11.2278 10.6621C11.2278 9.1991 10.2624 7.9623 8.92006 7.55506C9.59879 7.22324 10.0513 6.49926 10.0513 5.69986C10.0513 4.55356 9.13122 3.61841 7.96983 3.61841C6.82353 3.61841 5.88839 4.53848 5.88839 5.69986C5.88839 6.51434 6.35596 7.22324 7.01961 7.55506C5.69231 7.9623 4.71191 9.1991 4.71191 10.6621C4.74208 12.3062 5.9638 12.3816 7.53243 12.3816Z" />
    </SvgIcon>
  );
};

export default Group;
