import { Box, List, ListSubheader } from '@material-ui/core';
import { HospitalBuilding, HospitalShield, InsuranceCard, PinMapIcon } from 'components/Common/Icons';
import { i18n } from 'locale';
import SidebarMenuItem from './SidebarMenuItem';

const SidebarMenu = () => {
  return (
    <Box paddingX={6}>
      <List disablePadding>
        <ListSubheader>
          <Box marginTop={10}>{i18n.reporting}</Box>
        </ListSubheader>
        <SidebarMenuItem to="/reporting/territorial" icon={<PinMapIcon />}>
          {i18n.territorialReporting}
        </SidebarMenuItem>
        <SidebarMenuItem to="/reporting/hospital-group" icon={<HospitalBuilding />}>
          {i18n.ghtReporting}
        </SidebarMenuItem>
        <ListSubheader>
          <Box marginTop={10}>{i18n.management}</Box>
        </ListSubheader>
        <SidebarMenuItem to="/management/my-health-center" icon={<HospitalShield />}>
          {i18n.myHealthCenter}
        </SidebarMenuItem>
        <SidebarMenuItem to="/management/my-unit" icon={<InsuranceCard />}>
          {i18n.myUnit}
        </SidebarMenuItem>
      </List>
    </Box>
  );
};

export default SidebarMenu;
