import { Box, Grid, Switch } from '@material-ui/core';
import { Button } from 'components/Common';
import { FormContainer, FormRow, FormSection, InputIncrement } from 'components/Common/Form';
import { i18n } from 'locale';
import { color } from 'theme';
import { NormalizedUnit } from 'types';

type UnitEditModalFormProps = NormalizedUnit & {
  onSubmit: () => void;
};

const UnitEditModalForm: React.FC<UnitEditModalFormProps> = ({ name }) => {
  return (
    <Box borderLeft={'1px solid ' + color.grey[200]} paddingLeft={8}>
      <FormContainer label={name}>
        <FormSection>
          <FormRow label={i18n.covidAcceptance}>
            <Switch checked={true} onChange={() => {}} />
          </FormRow>
        </FormSection>
        <FormSection label={i18n.bedsInventory}>
          <FormRow label="Lits ouverts">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Lits fermés">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Disponibles Homme">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Disponibles Femme">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Disponibles indifférent">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
        </FormSection>
        <FormSection label={i18n.bedManagement}>
          <FormRow label="Entrées Jour J">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Sorties Jour J">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Entrées H+24">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Sorties H+24">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Entrées H+48">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
          <FormRow label="Sorties H+48">
            <InputIncrement
              value={3}
              onChange={(value) => {
                console.log(value);
              }}
            />
          </FormRow>
        </FormSection>
        <FormSection>
          <FormRow>
            <Box marginTop={2}>
              <Grid container spacing={4}>
                <Grid item>
                  <Button variant="outlined">{i18n.cancel}</Button>
                </Grid>
                <Grid item>
                  <Button>{i18n.save}</Button>
                </Grid>
              </Grid>
            </Box>
          </FormRow>
        </FormSection>
      </FormContainer>
    </Box>
  );
};
export default UnitEditModalForm;
