import { Grid } from '@material-ui/core';
import { Button } from 'components/Common';
import { DashboardHeaderTitle } from 'components/Dashboard';
import { i18n } from 'locale';
import Layout from 'modules/Layout';

const MyUnit = () => {
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item container justify="space-between" alignItems="center" xs={12}>
          <Grid item>
            <DashboardHeaderTitle title="Cardiologie" subtitle="Mis à jour le 26/04 à 17:00" />
          </Grid>
          <Grid item>
            <Button>{i18n.reporting} (4)</Button>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default MyUnit;
