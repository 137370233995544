import { Grid } from '@material-ui/core';

export type FormRowProps = {
  label?: string;
};

const FormRow: React.FC<FormRowProps> = ({ label, children }) => {
  return (
    <Grid container justify={!!label ? 'space-between' : 'flex-end'} alignItems="center">
      {!!label && <Grid item>{label}</Grid>}
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default FormRow;
