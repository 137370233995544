import { Grid, Typography } from '@material-ui/core';
import { i18n } from 'locale';
import { NormalizedUnit } from 'types';
import UnitsTableRow from './UnitsTableRow';
import UnitsTableSkeleton from './UnitsTableSkeleton';

export type FlowData = {
  inValue: number;
  outValue: number;
};

type UnitsTableProps = {
  units: NormalizedUnit[];
  loading: boolean;
};

const UnitsTable: React.FC<UnitsTableProps> = ({ units, loading }) => {
  return (
    <Grid container spacing={4} direction="column">
      <Grid container item>
        <Grid item xs={3}>
          <Typography variant="overline">{i18n.units}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">{i18n.occupancyRate}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="overline">{i18n.availableSpots}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">
            {i18n.input} | {i18n.output}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="overline">{i18n.next24}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="overline">{i18n.next48}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">{i18n.update}</Typography>
        </Grid>
      </Grid>
      <Grid container item>
        {loading ? (
          <UnitsTableSkeleton />
        ) : (
          <Grid container item direction="column" spacing={2}>
            {units.map((unit) => (
              <Grid item key={unit.objectId}>
                <UnitsTableRow {...unit} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default UnitsTable;
