export const translations = {
  login: 'Se connecter',
  logout: 'Se déconnecter',
  username: "Nom d'utilisateur",
  password: 'Mot de passe',
  bedManagement: 'Gestion de lits',
  stretchering: 'Brancardage',
  reporting: 'Reporting',
  ghtReporting: 'Reporting GHT',
  territorialReporting: 'Reporting territorial',
  occupancyRate: "Taux d'occupation",
  numberOfBeds: 'Nombre de lits',
  globalOccupancy: 'Occupation globale',
  management: 'Gestion',
  myUnit: 'Mon unité',
  myHealthCenter: 'Mon établissement',
  emergenciesOccupation: 'Occupation Urgences',
  search: 'Rechercher',
  updatedAt: 'Mis à jour le ',
  exportData: 'Exporter les données',
  beds: 'lits',
  total: 'Total',
  waitingForHospitalization: 'Attente hospitalisation',
  estimatedWaitingTime: "Temps d'attente estimé",
  units: 'Unités',
  availableSpots: 'Disponible',
  input: 'Entrées',
  output: 'Sorties',
  next24: '+H24',
  next48: '+H48',
  update: 'Mise à jour',
  updateShortened: 'Màj :',
  unitEdit: "Modification d'une unité",
  totalPatientsWaiting: 'Total de patients en attente',
  patientsWaitingForHospitalization: "Patients en attente d'hospitalisation",
  cancel: 'Annuler',
  confirmChanges: 'Valider les changements',
  covidAcceptance: 'Accueil COVID-19',
  bedsInventory: 'Inventaire des lits',
  bedsAvailable: 'Lits ouverts',
  save: 'Enregistrer',
  editDivision: 'Éditer un pôle',
  unitToEdit: 'Unité à éditer',
  goBackToHealthCenter: "Retourner à l'établissement",
  edit: 'Éditer',
  requests: 'Demandes',
  isEditing: 'En édition',
  man: 'Homme',
  woman: 'Femme',
  indifferent: 'Indifférent',
};
