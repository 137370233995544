import { Box, Grid, Typography } from '@material-ui/core';
import { MultipleSelect } from 'components/Common/Form';
import { UnitsTable } from 'components/Dashboard/Unit';
import { UnitsContext } from 'context';
import { useContext, useState } from 'react';

const HealthCenterUnitsReporting = () => {
  const { units, loading } = useContext(UnitsContext);

  const [serviceFilter, setServiceFilter] = useState<string[]>([]);
  const [unitFilter, setUnitFilter] = useState<string[]>([]);
  const [occupationRateFilter, setOccupationRateFilter] = useState<string[]>([]);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h3">Vue par pôles</Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <MultipleSelect
            label="Pôle"
            options={{ chir: 'Chirurgie', med: 'Médecine', urg: 'Urgences' }}
            value={serviceFilter}
            onChange={(filter) => setServiceFilter(filter)}
          />
        </Grid>
        <Grid item>
          <MultipleSelect
            label="Unité"
            options={{ chir: 'Cardiologie', med: 'Pneumologie', urg: 'Gériatrie' }}
            value={unitFilter}
            onChange={(filter) => setUnitFilter(filter)}
          />
        </Grid>
        <Grid item>
          <MultipleSelect
            label="Taux d'occupation"
            options={{ 0: '< 70%', 70: '> 70%', 90: '> 90%' }}
            value={occupationRateFilter}
            onChange={(filter) => setOccupationRateFilter(filter)}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Box bgcolor="secondary.light" marginX={-10}>
          <Box paddingX={10} paddingY={2} paddingBottom={8}>
            <UnitsTable units={units} loading={loading} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HealthCenterUnitsReporting;
