import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Download: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1665 9.33334C3.44265 9.33334 3.6665 9.5572 3.6665 9.83334V10.8333C3.6665 11.6618 4.33807 12.3333 5.1665 12.3333H10.8332C11.6616 12.3333 12.3332 11.6618 12.3332 10.8333V9.83334C12.3332 9.5572 12.557 9.33334 12.8332 9.33334C13.1093 9.33334 13.3332 9.5572 13.3332 9.83334V10.8333C13.3332 12.2141 12.2139 13.3333 10.8332 13.3333H5.1665C3.7858 13.3333 2.6665 12.2141 2.6665 10.8333V9.83334C2.6665 9.5572 2.89036 9.33334 3.1665 9.33334Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.66666C8.27614 2.66666 8.5 2.89051 8.5 3.16666V9.49999C8.5 9.77613 8.27614 9.99999 8 9.99999C7.72386 9.99999 7.5 9.77613 7.5 9.49999V3.16666C7.5 2.89051 7.72386 2.66666 8 2.66666Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49327 6.80026C5.69563 6.61236 6.01199 6.62408 6.1999 6.82643L8.00017 8.76519L9.80044 6.82643C9.98834 6.62408 10.3047 6.61236 10.5071 6.80026C10.7094 6.98816 10.7211 7.30453 10.5332 7.50688L8.36656 9.84022C8.27196 9.9421 8.1392 9.99999 8.00017 9.99999C7.86113 9.99999 7.72837 9.9421 7.63377 9.84022L5.4671 7.50688C5.2792 7.30453 5.29092 6.98816 5.49327 6.80026Z"
      />
    </SvgIcon>
  );
};

export default Download;
