import { LoginForm } from 'components/LoginForm';
import UserContext from 'context/UserContext';
import { useContext, useState } from 'react';
const wait = (ms: number = 2000) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
const LoginModal = () => {
  const { login } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const handleSubmit = async (username: string, password: string) => {
    setLoading(true);
    try {
      await wait();
      await login(username, password);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return <LoginForm onSubmit={handleSubmit} loading={loading} error={error} />;
};

export default LoginModal;
