import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

type StatisticsCardSkeletonProps = {
  displayProgress?: boolean;
};

const StatisticsCardSkeleton: React.FC<StatisticsCardSkeletonProps> = ({ displayProgress }) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      {displayProgress && (
        <Grid item>
          <Skeleton variant="circle" width={40} height={40} animation="wave" />
        </Grid>
      )}
      <Grid item>
        <Typography component="div" variant="caption">
          <Skeleton width={80} style={{ borderRadius: 4 }} animation="wave" />
        </Typography>
        <Typography component="div" variant="h2">
          <Skeleton width={40} style={{ borderRadius: 4 }} animation="wave" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatisticsCardSkeleton;
