import { makeStyles } from '@material-ui/core';

export const useInputStyles = makeStyles(() => ({
  container: {
    height: 40,
    width: 104,
  },
  input: {
    textAlign: 'center',
  },
}));
