import { ButtonProps as MaterialUiButtonProps, CircularProgress } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';

export type ButtonProps = MaterialUiButtonProps & {
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ loading = false, children, ...rest }) => {
  return (
    <MuiButton
      {...rest}
      disabled={rest.disabled || loading}
      endIcon={loading ? <CircularProgress color="inherit" size={14} /> : rest.endIcon}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
