import { Box, Button, Grid } from '@material-ui/core';
import { Group, HospitalBedroom } from 'components/Common/Icons';
import Download from 'components/Common/Icons/Download';
import { DashboardHeaderTitle } from 'components/Dashboard';
import { StatisticsCard, StatisticsCardItem } from 'components/Dashboard/Statistics';
import { i18n } from 'locale';
import { HealthCenterUnitsReporting } from 'modules/HealthCenterUnitsReporting';
import Layout from 'modules/Layout';
import { useEffect, useState } from 'react';

const wait = (ms: number = 2000) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const Home = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fakeLoading = async () => {
      await wait().then(() => setLoading(false));
    };

    fakeLoading();
  }, []);

  return (
    <Layout>
      <Grid container item direction="column" spacing={7}>
        <Grid container item justify="space-between" alignItems="center">
          <Grid item>
            <DashboardHeaderTitle title="Centre hospitalier de Périgueux" />
          </Grid>
          <Grid item>
            <Button variant="outlined" endIcon={<Download />}>
              {i18n.exportData}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Box padding={6} bgcolor="secondary.light" borderRadius={8}>
            <Grid container spacing={6}>
              <Grid item xs={12} md="auto">
                <StatisticsCard loading={loading} title={i18n.globalOccupancy} action={true}>
                  <StatisticsCardItem
                    loading={loading}
                    label={i18n.occupancyRate}
                    value={84}
                    displayProgress
                    percentage
                    Icon={Group}
                  />
                  <StatisticsCardItem
                    loading={loading}
                    label={i18n.numberOfBeds}
                    value={336}
                    total={400}
                    totalLabel="lits"
                  />
                </StatisticsCard>
              </Grid>
              <Grid item xs={12} md="auto">
                <StatisticsCard loading={loading} title={i18n.emergenciesOccupation} action={true}>
                  <StatisticsCardItem
                    loading={loading}
                    label={i18n.total}
                    value={28}
                    displayProgress
                    Icon={HospitalBedroom}
                  />
                  <StatisticsCardItem loading={loading} label={i18n.numberOfBeds} value="5" />
                  <StatisticsCardItem loading={loading} label={i18n.estimatedWaitingTime} value="1h30" />
                </StatisticsCard>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <HealthCenterUnitsReporting />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Home;
