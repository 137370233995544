import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Info: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" />
      <path
        d="M8.875 6.31232L6.25 6.59357V7.15607H6.83333C7.125 7.15607 7.125 7.36975 7.125 7.72021V11.3748C7.125 11.7797 6.96436 11.8467 6.25 11.9373V12.4998H9.75V11.9373C9.03564 11.8467 8.875 11.7797 8.875 11.3748V6.31232Z"
        fill="white"
      />
      <ellipse cx="8" cy="4.34375" rx="0.875" ry="0.84375" fill="white" />
    </SvgIcon>
  );
};

export default Info;
