import { ListItem, ListItemIcon } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export type SidebarMenuItemProps = {
  to: string;
  icon?: React.ReactNode;
};

const SidebarListItemIcon = styled(ListItemIcon)`
  color: inherit;
  font-size: 16px;
  min-width: 28px;
`;

const SidebarMenuItemText = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const SideBarListItem = styled(ListItem)`
  padding: 6px 0;
  color: #94a3b8;
  font-weight: 500;
  border-radius: 4px;
  margin-left: -12px;
  margin-right: 24px;
  width: calc(100% + 24px);
  padding: 16px;
  font-size: 14px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    color: #1e5cbb;
  }

  &.active {
    font-weight: 700;
    color: #1e5cbb;
    background-color: #ecf3ff;

    & > ${SidebarMenuItemText} {
      &::after  {
        content: '';
        background: #1e5cbb;
        border-radius: 3px;
        position: absolute;
        right: 0;
        height: 100%;
        width: 2px;
      }
    }

    & > ${SidebarListItemIcon} {
      color: #1e5cbb;
    }
  }
  min-height: 50px;
` as typeof ListItem;

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({ to, children, icon }) => {
  return (
    <SideBarListItem disableGutters component={NavLink} to={to}>
      <SidebarListItemIcon>{icon}</SidebarListItemIcon>
      <SidebarMenuItemText>{children}</SidebarMenuItemText>
    </SideBarListItem>
  );
};
export default SidebarMenuItem;
