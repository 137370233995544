import { ButtonBase, makeStyles, TextField } from '@material-ui/core';
import { AddIcon, RemoveIcon } from 'components/Common/Icons';
import React from 'react';
import { useInputStyles } from '../styles';

const useButtonStyles = makeStyles({
  button: {
    display: 'block',
    padding: 4,
    borderRadius: 4,
    height: 16,
    width: 16,
    backgroundColor: '#F1F5F9',
    color: 'black',
  },
  body: {
    display: 'flex',
    alignItems: 'center',
  },
});
type UsedButtonBaseProps = Pick<React.ComponentProps<typeof ButtonBase>, 'onClick'>;
const IncrementButton: React.FC<UsedButtonBaseProps> = ({ children, ...rest }) => {
  const styles = useButtonStyles();
  return (
    <ButtonBase color="inherit" {...rest} className={styles.button}>
      <div className={styles.body}>{children}</div>
    </ButtonBase>
  );
};

type UsedTextFieldProps = Pick<React.ComponentProps<typeof TextField>, 'disabled' | 'name'>;

export type InputIncrementProps = {
  value?: number;
  onChange: (value: number) => void;
} & UsedTextFieldProps;

const InputIncrement: React.FC<InputIncrementProps> = ({ value, onChange, ...rest }) => {
  const styles = useInputStyles();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value));
  };
  const increment = () => {
    onChange((value || 0) + 1);
  };
  const decrement = () => {
    onChange((value || 0) - 1);
  };
  return (
    <TextField
      type="number"
      variant="outlined"
      margin="dense"
      value={value || 0}
      fullWidth
      onChange={handleChange}
      InputProps={{
        inputProps: { className: styles.input },
        className: styles.container,
        startAdornment: (
          <IncrementButton onClick={decrement}>
            <RemoveIcon fontSize="small" />
          </IncrementButton>
        ),
        endAdornment: (
          <IncrementButton onClick={increment}>
            <AddIcon fontSize="small" />
          </IncrementButton>
        ),
      }}
      {...rest}
    />
  );
};

export default InputIncrement;
