import { schema } from 'normalizr';
const UserSchema = new schema.Entity(
  'User',
  {},
  {
    idAttribute: 'objectId',
    processStrategy: (user) => ({
      objectId: user.objectId,
      username: user.username,
      role: user.role,
    }),
  }
);

export default UserSchema;
