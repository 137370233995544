import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Button } from 'components/Common';
import { InputIncrement, InputTime } from 'components/Common/Form';
import { i18n } from 'locale';
import { useState } from 'react';
type FormValue = {
  numberOfBeds?: number;
};

export type UnitOptionModalProps = {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
};

const useStyles = makeStyles({
  iconButton: {
    position: 'absolute',
    right: 9,
    top: 9,
  },
});

const UnitOptionModal: React.FC<UnitOptionModalProps> = ({ onClose, loading, open }) => {
  const [formValue, setFormValue] = useState<FormValue>({});
  const styles = useStyles();
  const handleChangeNumber = (value: number) => {
    setFormValue({ ...formValue, numberOfBeds: value });
  };
  return (
    <Dialog open={open} onClose={loading ? () => {} : onClose}>
      <DialogTitle id="customized-dialog-title">
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h3">{i18n.unitEdit}</Typography>
          <IconButton onClick={onClose} disabled={loading} className={styles.iconButton}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={onClose}>
        <DialogContent>
          <Grid container>
            <Grid container item>
              <Grid container justify="space-between" alignItems="center">
                <Grid item component={Typography}>
                  {i18n.totalPatientsWaiting}
                </Grid>
                <Grid item>
                  <InputIncrement value={formValue.numberOfBeds} onChange={handleChangeNumber} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid container justify="space-between" alignItems="center">
                <Grid item component={Typography}>
                  {i18n.patientsWaitingForHospitalization}
                </Grid>
                <Grid item>
                  <InputIncrement value={formValue.numberOfBeds} onChange={handleChangeNumber} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid container justify="space-between" alignItems="center">
                <Grid item component={Typography}>
                  {i18n.estimatedWaitingTime}
                </Grid>
                <Grid item>
                  <InputTime />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            {i18n.cancel}
          </Button>
          <Button type="submit" loading={loading}>
            {i18n.confirmChanges}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UnitOptionModal;
