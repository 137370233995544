import color from './color';

const palette = {
  primary: {
    main: color.blue[600],
    dark: color.blue[700],
    light: color.blue[50],
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: color.grey[500],
    dark: color.grey[900],
    light: color.grey[50],
    contrastText: '#FFFFFF',
  },
  text: {
    primary: color.grey[900],
    secondary: color.grey[500],
    light: color.grey[500],
  },
  warning: {
    main: color.orange[500],
    dark: color.orange[900],
    light: color.orange[100],
    contrastText: '#FFFFFF',
  },
  success: {
    main: color.green[500],
    dark: color.green[900],
    light: color.green[100],
    contrastText: '#FFFFFF',
  },
  error: {
    main: color.red[500],
    dark: color.red[500],
    light: color.red[500],
    contrastText: '#FFFFFF',
  },
  default: {},
};

export default palette;
