import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { ArrowRight } from 'components/Common/Icons';
import { format } from 'date-fns';
import { i18n } from 'locale';
import { memo } from 'react';
import styled from 'styled-components';

const UnitCard = styled(Card)`
  border: 1px solid #e2e8f0;
  transition: all 0.15s ease-in;
  cursor: pointer;

  & .MuiSvgIcon-root {
    transition: transform 0.2s ease-out;
  }

  &:hover {
    box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.1), 0px 2px 4px -1px rgba(17, 24, 39, 0.06);

    & .MuiSvgIcon-root {
      transform: translate3d(2px, 0, 0);
    }
  }

  &:active {
    box-shadow: 0px 1px 2px rgba(17, 24, 39, 0.05);
  }

  &.selected {
    background: #f5f9ff;
    border: 1px solid #2e74dd;
    box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.1), 0px 2px 4px -1px rgba(17, 24, 39, 0.06);
  }
`;

type UnitEditModalUnitCardProps = {
  name: string;
  updatedAt: string;
  selected?: boolean;
  onClick: () => void;
};

const UnitEditModalUnitCard: React.FC<UnitEditModalUnitCardProps> = ({
  name,
  updatedAt,
  onClick,
  selected = false,
}) => {
  return (
    <UnitCard onClick={onClick} elevation={1} className={selected ? 'selected' : ''}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">{name}</Typography>
            <Typography>
              {i18n.updateShortened} {format(new Date(updatedAt), 'dd/MM/yyyy à HH:mm')}
            </Typography>
          </Grid>
          <Grid item>
            <ArrowRight />
          </Grid>
        </Grid>
      </CardContent>
    </UnitCard>
  );
};

export default memo(UnitEditModalUnitCard);
