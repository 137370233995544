import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RemoveIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 8 2" {...props}>
      <path d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H7.25C7.66421 0.25 8 0.585786 8 1C8 1.41421 7.66421 1.75 7.25 1.75H0.75C0.335786 1.75 0 1.41421 0 1Z" />
    </SvgIcon>
  );
};

export default RemoveIcon;
