import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChevronUp: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.14645 10.3536C2.34171 10.5488 2.65829 10.5488 2.85355 10.3536L8 5.5L13.1464 10.3536C13.3417 10.5488 13.6583 10.5488 13.8536 10.3536C14.0488 10.1583 14.0488 9.84171 13.8536 9.64645L8.70711 4.79289C8.31658 4.40237 7.68342 4.40237 7.29289 4.79289L2.14645 9.64645C1.95118 9.84171 1.95118 10.1583 2.14645 10.3536Z"
      />
    </SvgIcon>
  );
};

export default ChevronUp;
