import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChevronDown: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M2.14645 5.64645C2.34171 5.45118 2.65829 5.45118 2.85355 5.64645L8 10.5L13.1464 5.64645C13.3417 5.45118 13.6583 5.45118 13.8536 5.64645C14.0488 5.84171 14.0488 6.15829 13.8536 6.35355L8.70711 11.2071C8.31658 11.5976 7.68342 11.5976 7.29289 11.2071L2.14645 6.35355C1.95118 6.15829 1.95118 5.84171 2.14645 5.64645Z" />
    </SvgIcon>
  );
};

export default ChevronDown;
