import { SvgIcon, SvgIconProps } from '@material-ui/core';

const List: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <rect x="3" y="2" width="13" height="2" rx="1" />
      <circle cx="1" cy="3" r="1" />
      <rect x="3" y="7" width="13" height="2" rx="1" />
      <circle cx="1" cy="8" r="1" />
      <rect x="3" y="12" width="13" height="2" rx="1" />
      <circle cx="1" cy="13" r="1" />
    </SvgIcon>
  );
};

export default List;
