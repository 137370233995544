import Api from '@ambuliz/sabri-core';
import { ThemeProvider } from '@material-ui/styles';
import App from 'App';
import { UserContextProvider } from 'context/UserContext';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from 'theme';
import './index.css';

Api.initialize(process.env.REACT_APP_PARSE_KEY || '');
Api.enableLocalDatastore();
Api.serverURL = process.env.REACT_APP_PARSE_URL || '';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
