import { Chip as MuiChip, ChipProps as MuiChipProps, Theme, withTheme } from '@material-ui/core';
import { ReactElement } from 'react';
import styled from 'styled-components';

export type ChipProps = {
  label: string;
  icon?: ReactElement<any, string>;
  color?: 'default' | 'success' | 'warning' | 'error' | 'primary' | 'secondary';
  reverse?: boolean;
};

export type ColoredChipProps = MuiChipProps & {
  chipcolor: 'default' | 'success' | 'warning' | 'error' | 'primary' | 'secondary';
  reverse?: string;
};

const ColoredChip: React.FC<ColoredChipProps> = withTheme(styled(MuiChip)`
  background-color: ${(props: ColoredChipProps & { theme: Theme }) => {
    return props.chipcolor !== 'default' && props.theme.palette[props.chipcolor].main;
  }};
  color: ${(props: ColoredChipProps & { theme: Theme }) => {
    return props.chipcolor !== 'default' && props.theme.palette[props.chipcolor].contrastText;
  }};
  direction: ${(props) => (props.reverse ? 'rtl' : 'ltr')};
  & > .MuiSvgIcon-root {
    margin: ${(props) => (props.reverse ? '0 8px 0 0' : '0 0 0 8px')};
  }
  & > .MuiChip-icon {
    color: ${(props: ColoredChipProps & { theme: Theme }) => {
      return props.chipcolor !== 'default' && props.theme.palette[props.chipcolor].contrastText;
    }};
  }
`) as typeof MuiChip;

const Chip: React.FC<ChipProps> = ({ label, icon, color = 'default', reverse = false }) => {
  return <ColoredChip label={label} icon={icon} chipcolor={color} reverse={reverse ? reverse.toString() : ''} />;
};

export default Chip;
