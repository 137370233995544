import { Box, Typography } from '@material-ui/core';

export type DashboardHeaderTitleProps = {
  title: string;
  subtitle?: string;
};

const DashboardHeaderTitle: React.FC<DashboardHeaderTitleProps> = ({ title, subtitle }) => {
  return (
    <>
      <Typography variant="h1">{title}</Typography>
      {subtitle && (
        <Box marginTop="4px">
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Box>
      )}
    </>
  );
};

export default DashboardHeaderTitle;
